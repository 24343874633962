@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

$first-color: #16d899;

$text-normal: #1b222b;
$text-light: #7c828c;
$text-lighter: #a2a6b7;
$text-white: #fff;

$red: #e12a02;
$red-border: #f19c87;
$red-bg: #fce9e5;

$blue: #2046fc;
$blue-border: #9eb0fd;
$blue-bg: #f3f6ff;

$green: #48a02e;
$green-border: #add59f;
$green-bg: #edf6eb;

$orange: #efa333;
$orange-border: #f9d69e;
$orange-bg: #fff8e6;

$purple: #735bf2;
$purple-border: #b493fa;
$purple-bg: #f0ebfb;

$bg-main: #eff3f8;
$bg-light: #ffffff;

$border-color: #dfe5f0;

$font: "Poppins", sans-serif;
$mobile-width: 620px;
$tablet-width: 1024px;

@mixin truncate($line) {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin scrollOpacity($width) {
  &::before,
  &:after {
    content: "";
    position: absolute;
    width: $width;
    height: 100%;
    top: 0;
    z-index: 9;
  }
  &::before {
    left: 0;
    background: linear-gradient(to right, rgb(255, 255, 255), rgba(0, 0, 0, 0));
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, rgb(255, 255, 255), rgba(0, 0, 0, 0));
  }
}
@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font;
}
body {
  background-color: $bg-main;
}
button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
a {
  text-decoration: none;
}
.app-container {
  padding: 2rem 0.5rem;
  .calendar-box {
    position: relative;
    max-width: 1200px;
    height: calc(100vh - 4rem);
    width: 100%;
    border-radius: 1rem;
    background-color: $bg-light;
    margin: auto;
    .pad-x {
      padding: 0 2.5rem;
      @include tablet {
        padding: 0 1.5rem;
      }
    }
    .header {
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        position: relative;
        color: $text-normal;
        height: 80px;
        width: max-content;
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 600;
        padding-bottom: 0.25rem;
        &::before {
          content: "";
          width: 100%;
          height: 4px;
          border-radius: 0.5rem;
          background-color: $first-color;
          position: absolute;
          left: 0;
          bottom: -2px;
        }
      }
      .header-buttons {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .header-button {
          padding: 0.75rem 1.25rem;
          background-color: $bg-main;
          border-radius: 0.5rem;
          font-size: 0.9rem;
          color: $text-normal;
          display: flex;
          align-items: center;
          gap: 0.75rem;
          .country-logo {
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }
        }
      }
    }
    .error-msg,
    .success-msg,
    .warning-msg {
      width: 100%;
      font-size: 0.7rem;
      border-radius: 0.25rem;
      padding: 0.4rem 0.5rem;
      margin-top: 0.25rem;
    }
    .error-msg {
      color: $red;
      background-color: $red-bg;
      border: 1px solid $red-border;
    }
    .success-msg {
      color: $green;
      background-color: $green-bg;
      border: 1px solid $green-border;
    }
    .warning-msg {
      color: $orange;
      background-color: $orange-bg;
      border: 1px solid $orange-border;
    }
  }
}
@import "./dates.scss";
@import "./tasks.scss";
@import "./form.scss";
