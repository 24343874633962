.content-grid {
  display: grid;
  grid-template-columns: 400px calc(100% - 400px);
  gap: 1rem;
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.content {
  &.month-content {
    @media screen and (max-width: 850px) {
      display: none;
    }
  }
  .day-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    .day-title {
      color: $text-normal;
      font-weight: 600;
      font-size: 1.5rem;
      padding: 1.5rem 0;
      .month-day {
        font-size: 0.6rem;
        color: $text-lighter;
        font-weight: 400;
        padding-left: 0.25rem;
      }
    }
    .add-btn {
      padding: 1rem 1.5rem;
      border: 1px dashed $first-color;
      background-color: $first-color;
      border-radius: 0.5rem;
      color: $text-white;
      font-size: 0.9rem;
      transition: all 0.25s;
      .bi {
        padding-right: 0.5rem;
      }
      &:hover {
        background-color: transparent;
        color: $text-normal;
      }
      &:active {
        transform: scale(0.8);
      }
    }
  }
  .tasks {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    height: calc(100vh - 370px);
    .task-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.5rem;
      border-radius: 0.75rem;
      background-color: #fff;
      border: 1px solid $border-color;
      cursor: pointer;
      gap: 0.5rem;
      &.past {
        border-color: $border-color;
        background-color: $bg-main;
        color: $text-light;
      }
      &.red {
        border-color: $red-border;
        background-color: $red-bg;
        color: $red;
      }
      &.blue {
        border-color: $blue-border;
        background-color: $blue-bg;
        color: $blue;
      }
      &.green {
        border-color: $green-border;
        background-color: $green-bg;
        color: $green;
      }
      &.orange {
        border-color: $orange-border;
        background-color: $orange-bg;
        color: $orange;
      }
      &.purple {
        border-color: $purple-border;
        background-color: $purple-bg;
        color: $purple;
      }
      &.done {
        .task-text,
        .task-time {
          text-decoration: line-through;
        }
      }
      .task-info {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        .task-time {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 0.75rem;
          .time-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            &.past {
              background-color: $text-light;
            }
            &.red {
              background-color: $red;
            }
            &.blue {
              background-color: $blue;
            }
            &.green {
              background-color: $green;
            }
            &.orange {
              background-color: $orange;
            }
            &.purple {
              background-color: $purple;
            }
          }
          .time-past {
            font-size: 0.5rem;
          }
        }
        .task-text {
          font-size: 0.8rem;
          @include truncate(2);
          &.month-text {
            @include truncate(1);
          }
        }
      }
      .arrows {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .bi {
          font-size: 0.75rem;
        }
        .animation {
          transform: translateX(-40px);
          opacity: 0;
        }
        .animation:nth-child(1) {
          transition: all 0.5s;
        }
        .animation:nth-child(2) {
          transition: all 0.25s;
        }
      }
      &:hover .arrows .animation {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}
