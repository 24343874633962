.form-area {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  display: grid;
  place-items: center;
  border-radius: 1rem;
  opacity: 0;
  transition: all 0.25s;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: visible;
    .form {
      transform: scale(1);
    }
  }
  .form {
    position: relative;
    max-width: 450px;
    width: 100%;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    transform: scale(0.25);
    transition: all 0.25s;
    .form-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .form-title {
        position: relative;
        width: max-content;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        color: $text-normal;
        font-size: 1.5rem;
        font-weight: 600;
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 4px;
          background-color: $first-color;
          border-radius: 0.5rem;
        }
      }
      .popup-buttons {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .popup-btn {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          font-size: 0.8rem;
          padding: 0.5rem;
          outline: none;
          border-radius: 0.5rem;
          color: $text-light;
          background-color: $bg-main;
          border: 1px solid $border-color;
          &.red {
            color: $red;
            background-color: $red-bg;
            border: 1px solid $red-border;
          }
        }
      }
    }
    .input-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      .form-info {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1rem;
        background-color: $bg-main;
        border-radius: 0.5rem;
        border: 1px solid $border-color;
        color: $text-light;
        font-size: 0.9rem;
      }
      label {
        color: $text-normal;
        font-size: 1rem;
        font-weight: 500;
      }
      .input {
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid $border-color;
        font-size: 1rem;
        color: $text-normal;
        outline: none;
        &::placeholder {
          color: $text-lighter;
        }
        &.text-input {
          height: 100px;
          resize: none;
        }
      }
      .color-buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        height: 100%;
        .color-btn {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          border: 1px dotted transparent;
          &.active {
            &.red {
              border-color: $red;
            }
            &.blue {
              border-color: $blue;
            }
            &.green {
              border-color: $green;
            }
            &.orange {
              border-color: $orange;
            }
            &.purple {
              border-color: $purple;
            }
          }
          label {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            cursor: pointer;
            &.red {
              background-color: $red;
            }
            &.blue {
              background-color: $blue;
            }
            &.green {
              background-color: $green;
            }
            &.orange {
              background-color: $orange;
            }
            &.purple {
              background-color: $purple;
            }
          }
        }
      }
      .radio-input {
        display: none;
      }
      .status-labels {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 0.5rem;
        .status-label {
          font-weight: 400;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0.75rem 1rem;
          border-radius: 0.5rem;
          border: 1px solid $border-color;
          font-size: 0.7rem;
          color: $text-normal;
          transition: all 0.25s;
          cursor: pointer;
          .label-detail {
            font-size: 0.5rem;
            color: $text-lighter;
          }
        }
        input:checked + .status-label {
          border-color: $first-color;
        }
      }
    }
    .submit-btn {
      background-color: $first-color;
      padding: 0.75rem 0.5rem;
      border-radius: 0.5rem;
      color: $text-white;
      cursor: pointer;
      transition: all 0.25s;
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(0.8);
      }
    }
  }
}
