.dates {
  .date-times {
    width: 100%;
    height: 60px;
    display: grid;
    align-items: center;
    grid-template-columns: 150px calc(100% - 150px);
    border-bottom: 1px solid $border-color;
    .date-year {
      height: 100%;
      padding: 0 1rem 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0.25rem;
      font-size: 0.9rem;
      border-right: 1px solid $border-color;
      .year-btn {
        color: $text-light;
        padding: 0.25rem;
        transition: all 0.2s;
        &:active {
          transform: scale(0.9);
        }
      }
    }
    .date-months {
      position: relative;
      height: 100%;
      .months {
        display: grid;
        height: 100%;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        overflow-x: auto;
        .month {
          position: relative;
          height: 100%;
          min-width: 65px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: all 0.25s;
          &:hover {
            background-color: $bg-main;
            color: $text-white;
          }
          .month-name {
            width: max-content;
            padding: 0.25rem 0.5rem;
            background-color: transparent;
            border-radius: 0.25rem;
            color: $text-light;
            transition: all 0.25s;
          }
          &.active {
            .month-name {
              background-color: $first-color;
              color: $text-white;
            }
          }
          .month-fill {
            position: absolute;
            display: none;
            bottom: 8px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $first-color;
          }
          &.fill {
            .month-fill {
              display: block;
            }
          }
        }
        @include tablet {
          @include scrollOpacity(2rem);
        }
      }
    }
  }
  .date-days {
    position: relative;
    border-bottom: 1px solid $border-color;
    .days {
      position: relative;
      display: grid;
      align-items: center;
      height: 60px;
      overflow-x: auto;
      .day-btn {
        min-width: 35px;
        height: 100%;
        transition: all 0.25s;
        display: flex;
        align-items: center;
        justify-content: center;
        .day {
          padding: 0.25rem 0.4rem;
          border-radius: 0.25rem;
          background-color: transparent;
          color: $text-light;
          font-size: 0.8rem;
        }
        .day-fill {
          position: absolute;
          display: none;
          bottom: 7px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $first-color;
        }
        &.fill {
          .day-fill {
            display: block;
          }
        }
        &.active {
          .day {
            background-color: $first-color;
            color: $text-white;
          }
        }
        &:hover {
          background-color: $bg-main;
        }
      }
    }
    @include scrollOpacity(2rem);
  }
}
